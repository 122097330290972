















































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'
import {
  HomeworkController,
  CourseController,
  DropDownController,
} from '@/services/request.service'
import moment from 'moment'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { i18n } from '@/i18n/i18n'
import filterGroup from '@/components/filterGroup'
import { toPage, clearEmptyArray } from '@/utils/utils'
import { createPagination } from '@/constant/constant'

@Component({
  components: {
    FlexTooltip,
    filterGroup,
  },
})
export default class HomeworkViewer extends Vue {
  @Prop() private readonly type!: string
  private loading: boolean = false
  private statuses: Array<any> = ['1021', '1022']
  private filter: any = {
    courseId: undefined,
    time: [],
    status: 0,
    schoolYearId: undefined,
  }
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private document = document
  private data: any = []
  private moment = moment
  private schoolYearList: Array<any> = []
  private courses: Array<any> = []

  @Watch('type', { immediate: true })
  private onTypeChange(to, from): void {
    this.filter.courseId = []
    this.filter.time = []
    this.filter.status = 0
    // this.judeOperateAuth()
    this.getDropDownInfo()
    this.getData()
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'name',
        title: this.$t('homework.name'),
        align: 'left',
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      this.type === 'courseClass'
        ? {
            dataIndex: 'sectionName',
            key: 'sectionName',
            title: this.$t('homework.sectionName'),
            align: 'left',
            scopedSlots: { customRender: 'sectionName' },
          }
        : -1,
      this.type === 'courseClass'
        ? {
            dataIndex: 'subjectName',
            key: 'subjectName',
            ellipsis: true,
            title: this.$t('homework.subjectName'),
            scopedSlots: { customRender: 'subjectName' },
            align: 'left',
          }
        : -1,
      this.type === 'courseClass'
        ? {
            dataIndex: 'courseName',
            key: 'courseName',
            ellipsis: true,
            title: this.$t('homework.courseName'),
            scopedSlots: { customRender: 'courseName' },
            align: 'left',
          }
        : {
            dataIndex: 'courseName',
            key: 'CCAClassName',
            ellipsis: true,
            title: this.$t('homework.CCAClass'),
            scopedSlots: { customRender: 'courseName' },
            align: 'left',
          },
      {
        key: 'endTime',
        title: this.$t('homework.deadlineTime'),
        align: 'left',
        width: 100,
        scopedSlots: { customRender: 'endTime' },
      },
      {
        key: 'completeNum',
        title: this.$t('homework.complete'),
        align: 'right',
        width: 90,
        scopedSlots: { customRender: 'completeNum' },
      },
      {
        key: 'scored',
        title: this.$t('homework.scored'),
        align: 'right',
        width: 90,
        scopedSlots: { customRender: 'scored' },
      },
      {
        dataIndex: 'creatorName',
        key: 'creatorName',
        title: this.$t('homework.creatorName'),
        ellipsis: true,
        align: 'left',
        scopedSlots: { customRender: 'creatorName' },
      },
      {
        key: 'publishTime',
        title: this.$t('homework.publishTime'),
        align: 'left',
        ellipsis: true,
        scopedSlots: { customRender: 'createTime' },
      },
      {
        key: 'operations',
        title: this.$t('common.operations'),
        align: 'left',
        width: 100,
        scopedSlots: { customRender: 'operations' },
      },
    ].filter(item => item !== -1)
  }

  private get userId(): any {
    return this.$store.state.memberId
  }

  private get currentSchoolYearId(): any {
    return this.$store.state.currentSchoolYear.schoolYearId
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private reset(): void {
    this.filter.courseId = []
    this.filter.time = []
    this.filter.status = 0
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private created() {
    this.getSchoolYearList()
  }

  private viewDetail(homeworkId): void {
    this.$router.push({ name: 'homeworkDetail', params: { homeworkId } })
    // this.$router.push({ path: `homeworkDetail/${homeworkId}` }) // -> /user/123
  }

  private terminate(homeworkId): void {
    HomeworkController.delete(homeworkId)
      .then(res => {
        this.$message.success(this.$tc('common.deleteSuccess'))
        this.refresh()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private canDelete(item): any {
    return item.creator === this.userId && this.operationAuths.includes('2022')
  }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private publishHomework(): void {
    this.$router.push({
      name: 'publishHomework',
      query: { defaultClassId: this.filter.courseId === 0 ? undefined : this.filter.courseId },
    })
  }

  private getDropDownInfo(): void {
    if (!this.filter.schoolYearId) return
    if (this.type === 'courseClass') {
      this.filter.courseId = []
      CourseController.getCoursesCascadeBySchoolYear(this.filter.schoolYearId)
        .then(res => {
          this.courses = clearEmptyArray(res.data)
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      this.filter.courseId = 0
      DropDownController.getCcaCourseBySchoolYear(this.filter.schoolYearId)
        .then(res => {
          this.courses = res.data
        })
        .catch(err => console.log(err))
    }
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearList()
      .then(res => {
        this.schoolYearList = res.data
        this.filter.schoolYearId = (res.data[0] || {}).key
        // this.filter.schoolYearId = (res.data.filter(item => item.key === this.currentSchoolYearId)[0] || {}).key
        if (this.filter.schoolYearId) {
          this.getDropDownInfo()
        }
      })
      .finally(() => this.getData())
  }

  private changeYear(): void {
    this.$nextTick(() => {
      this.getDropDownInfo()
      this.getData()
    })
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.data = []
    this.loading = true
    const request = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      courseId:
        this.filter.courseId.length !== 0 && this.type === 'courseClass'
          ? this.filter.courseId[1]
          : this.filter.courseId === 0
          ? undefined
          : this.filter.courseId,
      startTime: this.filter.time.length !== 0 ? moment(this.filter.time[0]).valueOf() : undefined,
      endTime: this.filter.time.length !== 0 ? moment(this.filter.time[1]).valueOf() : undefined,
      status: this.filter.status !== 0 ? this.filter.status : undefined,
      type: this.type === 'courseClass' ? '1041' : '1042',
      schoolYearId: this.filter.schoolYearId || undefined,
    }
    HomeworkController.getList(request)
      .then(res => {
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private refresh(): void {
    this.getData(this.pagination)
  }
}
