




















































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import GroupTransfer from '@/components/GroupTransfer.vue'
import cloneDeep from 'lodash/cloneDeep'
import { DropDownController, SenController, TeacherController } from '@/services/request.service'
import { fileUploader } from '@/services/qiniu.service'
import moment from 'moment'
import Upload from '@/components/Upload.vue'
Component.registerHooks(['beforeRouteLeave'])

const operations = {
  add: function(condition) {
    return SenController.add(condition)
  },
  edit: function(condition) {
    return SenController.update(condition)
  },
}

@Component({
  components: {
    GroupTransfer,
    Upload,
  },
})
export default class SenEditor extends Vue {
  private accommodationsAndInterventions: any = []
  private candidate: any = []
  private canLeave: boolean = false
  private dataLoading: boolean = false
  private formLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }
  private groupTransferTitle: any = ''
  private groupTransferVis: boolean = false
  private learningSpecialties: any = []
  private loading: boolean = false
  private selectedData: any = []
  private senEditor: any = {}
  private students: any = []
  private teachers: any = []
  private transferType: any = 'teachers'
  private uploading: any = false

  private get senId(): any {
    return this.$route.query.id
  }

  private get studentError(): any {
    return this.senEditor.students && !(this.senEditor.students || []).length
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get type(): any {
    return this.$route.query.type || 'add'
  }

  private get teacherError(): any {
    return this.senEditor.teachers && !(this.senEditor.teachers || []).length
  }

  private set teacherError(val) {
    this.$set(this.senEditor, 'teachers', [])
  }

  private set studentError(val) {
    this.$set(this.senEditor, 'students', [])
  }

  private addTeacher(): void {
    this.groupTransferVis = true
    this.candidate = this.teachers
    this.selectedData = this.senEditor.teachers || []
    this.transferType = 'teachers'
    this.groupTransferTitle = this.$t('message.addTeachers')
  }

  private addStudent(): void {
    this.groupTransferVis = true
    this.candidate = this.students
    this.selectedData = this.senEditor.students || []
    this.transferType = 'students'
    this.groupTransferTitle = this.$t('message.addStudents')
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (!this.canLeave) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private cancel(): void {
    this.$router.back()
  }

  private created(): void {
    this.getDropDownInfo()
  }

  private getDropDownInfo(): void {
    this.dataLoading = true
    Promise.all([
      TeacherController.getListAll(),
      DropDownController.getHeadTeachers(),
      DropDownController.getCourseTeachers(),
      DropDownController.getTutors(),
      DropDownController.getAuthStudentsGroupByClass(['1014']),
    ])
      .then(res => {
        const allTeachers = res[0].data.map(teacher => {
          return {
            title: teacher.enName + teacher.name,
            id: teacher.teacherId,
            selected: false,
            type: 'teacher',
            relation: '',
          }
        })
        const headTeachers = res[1].data.map((grade, index) => {
          return {
            id: index,
            expand: false,
            title: grade.groupLabel || this.$t('transfer.ungrouped'),
            children: grade.list.map(teacher => {
              return {
                id: teacher.teacherId,
                title: teacher.teacherName,
                titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
              }
            }),
          }
        })
        const subjectTeachers = res[2].data.map((subject, index) => {
          return {
            id: index,
            expand: false,
            title: subject.groupLabel || this.$t('transfer.ungrouped'),
            children: subject.list.map(teacher => {
              return {
                id: teacher.teacherId,
                title: teacher.teacherName,
                titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
              }
            }),
          }
        })
        const tutors = res[3].data.map((advisory, index) => {
          return {
            id: index,
            expand: false,
            title: advisory.groupLabel || this.$t('transfer.ungrouped'),
            children: advisory.list.map(teacher => {
              return {
                id: teacher.teacherId,
                title: teacher.teacherName,
                titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
              }
            }),
          }
        })
        this.teachers = {
          all: {
            list: allTeachers,
            transferType: 'normal',
          },
          grade: {
            list: headTeachers,
            transferType: 'group',
          },
          advisory: {
            list: tutors,
            transferType: 'group',
          },
          subjectClass: {
            list: subjectTeachers,
            transferType: 'group',
          },
        }

        this.students = res[4].data.map(item => {
          return {
            id: item.classId,
            title: item.className || this.$t('transfer.undivided'),
            expand: false,
            children: item.students.map(it => {
              return {
                id: it.studentId,
                title: it.name,
              }
            }),
          }
        })
      })
      .finally(() => (this.dataLoading = false))
    Promise.all([
      DropDownController.getSenTypes('1001'),
      DropDownController.getSenTypes('1002'),
    ]).then(res => {
      this.learningSpecialties = res[0].data
      this.accommodationsAndInterventions = res[1].data
    })
  }

  private getSelectedData(selectedData): void {
    this.$set(this.senEditor, this.transferType, cloneDeep(selectedData))
  }

  private getSenInfo(): void {
    SenController.detail(this.senId).then(res => {
      const {
        firstTypes,
        secondTypeIds,
        meetingTime,
        location,
        description,
        students,
        teachers,
        resources,
      } = res.data
      this.senEditor = {
        learningSpecialties: firstTypes.map(item => item.senTypeId),
        accommodationsAndInterventions: secondTypeIds.map(item => item.senTypeId),
        meetingTime: moment(meetingTime),
        location,
        description,
        students: students.map(item => ({
          id: item.studentId,
          title: item.name,
        })),
        teachers: teachers.map(item => ({
          id: item.teacherId,
          title: `${item.enName} ${item.name}`.trim(),
        })),
        attachments: resources.map(item => {
          return {
            uid: item.resourceId,
            url: item.resourceUrl,
            name: item.resourceName,
          }
        }),
      }
    })
  }

  private removeItem(id, type): void {
    if (type === 'teacher') {
      const index = this.senEditor.teachers.findIndex(teacher => teacher.id === id)
      this.senEditor.teachers.splice(index, 1)
    } else {
      const index = this.senEditor.students.findIndex(student => student.id === id)
      this.senEditor.students.splice(index, 1)
    }
  }

  private saveSen(): void {
    ;(this.$refs['senForm'] as any).validate(valid => {
      const { teachers, students } = this.senEditor
      let otherValid = true
      if (!(teachers || []).length || !(students || []).length) {
        if (!(teachers || []).length) {
          this.teacherError = true
        }
        if (!(students || []).length) {
          this.studentError = true
        }
        otherValid = false
      }
      if (valid && otherValid) {
        this.loading = true
        const condition = {
          ...this.senEditor,
          firstTypeIds: this.senEditor.learningSpecialties,
          secondTypeIds: this.senEditor.accommodationsAndInterventions,
          meetingTime: this.senEditor.meetingTime.valueOf(),
          studentIds: this.senEditor.students.map(student => student.id),
          teacherIds: this.senEditor.teachers.map(teacher => teacher.id),
          senId: this.senId || undefined,
          resourceIds: (this.senEditor.attachments || []).map(item => item.uid),
        }
        operations[this.type](condition)
          .then(res => {
            this.$message.success(this.$tc('common.saveSuccess'))
            this.senEditor = {}
            this.canLeave = true
            this.$route.meta.refresh = true
            this.$router.back()
          })
          .catch(err => console.log(err))
          .finally(() => (this.loading = false))
      } else {
        return false
      }
    })
  }

  private mounted() {
    ;(this.$refs.ELSelect as Vue).$el.querySelectorAll('input')[0]?.setAttribute('readonly', '')
    ;(this.$refs.EASelect as Vue).$el.querySelectorAll('input')[0]?.setAttribute('readonly', '')
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.hash) {
      this.$router.replace({ name: 'sen' })
    }
    if (to.query.type === 'edit') {
      to.meta.routerName = 'editSen'
      this.getSenInfo()
    } else {
      to.meta.routerName = 'addSen'
    }
  }
}
