



































































































































































































import moment from 'moment'
import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import {
  HomeworkController,
  CourseController,
  DropDownController,
} from '@/services/request.service'
import { fileUploader } from '@/services/qiniu.service'
import debounce from 'lodash/debounce'
import Upload from '@/components/Upload.vue'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'highlight.js/styles/nord.css'

import hljs from 'highlight.js'
import { quillEditor } from 'vue-quill-editor'
import Quill from 'quill'
import ImageResize from 'quill-image-resize-module'
import { clearEmptyArray } from '@/utils/utils'

Quill.register('modules/imageResize', ImageResize)

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    quillEditor,
    Upload,
  },
})
export default class PublishHomeWork extends Vue {
  private uploading: boolean = false
  private addRange: any
  private spinning: boolean = false
  private imgUploading: boolean = false
  private homeworkContent: any = ''
  private canLeave: boolean = false
  private saveLoading: boolean = false
  private courses: Array<any> = []
  private data: any = {}
  private InfoForm: any
  private handInOnline: boolean = true
  private formLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }

  private get locale(): string {
    return this.$store.state.locale
  }
  private get editorOption(): any {
    return {
      placeholder: this.$t('homework.enterHomeworkContent'),
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'image', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ color: [] }, { background: [] }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ align: [] }],
        ],
        imageResize: {
          handleStyles: {
            backgroundColor: 'transparent',
            border: 'none',
            color: 'white',
          },
          displayStyles: {
            backgroundColor: 'transparent',
            border: 'none',
            color: 'white',
          },
          modules: ['Resize', 'DisplaySize', 'Toolbar'],
        },
        syntax: {
          highlieght: text => hljs.highlightAuto(text).value,
        },
      },
    }
  }
  private get type(): any {
    return this.$route.params.classType || 'courseClass'
  }
  private get defaultClassId(): any {
    return this.$route.query.defaultClassId || []
  }
  private get editor(): any {
    const homeworkEditor = this.$refs.homeworkEditor as any
    return homeworkEditor.quill
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (!this.canLeave) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private beforeCreate(): void {
    this.InfoForm = this.$form.createForm(this)
  }

  private created(): void {
    this.$nextTick(() => {
      this.getDropDownInfo()
    })
  }

  private mounted(): void {
    this.editor.getModule('toolbar').addHandler('image', this.imgHandler)
  }

  private imgHandler(state): void {
    this.addRange = this.editor.getSelection()
    if (state) {
      let fileInput = document.getElementById('imgInput') as any
      fileInput.click() // 加一个触发事件
    }
  }

  private disabledDate(current): any {
    return current && current < moment().startOf('day')
  }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private cancel(): void {
    this.$router.back()
  }

  private saveHomework(): void {
    this.saveLoading = true
    const homeworkForm = this.InfoForm
    homeworkForm.validateFields((err: any, values: any) => {
      if (err) {
        this.saveLoading = false
        return
      } else {
        if (this.homeworkContent === '') {
          this.$message.warning(this.$tc('homework.contentNotEmptyTip'))
          this.saveLoading = false
          return
        } else {
          const request = {
            ...values,
            resourceIds: values.attachments.map(item => item.uid),
            courseId: this.type === 'courseClass' ? values.courseId[1] : values.courseId,
            endTime: moment(values.deadlineTime).valueOf(),
            content: this.homeworkContent,
            type: this.type === 'courseClass' ? '1041' : '1042',
          }
          HomeworkController.add(request)
            .then(res => {
              this.$message.success(this.$tc('common.saveSuccess'))
              this.canLeave = true
              this.$route.meta.refresh = true
              this.$router.push({ name: 'homework', query: { type: this.type } })
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              this.saveLoading = false
            })
        }
      }
    })
  }

  private customRequestImg({ file }): void {
    const index = file.name.lastIndexOf('.')
    const suffix = file.name.slice(index).toLowerCase()
    // 构造文件名
    const fileName = 'file_' + new Date().getTime() + suffix
    const suffixArray = ['.jpg', '.jpeg', '.png']
    if (suffixArray.includes(suffix)) {
      if (file.size <= 104857600) {
        this.imgUploading = true
        this.spinning = true
        fileUploader(file, fileName, 'file')
          .then(res => {
            let url = process.env.VUE_APP_FILE_URL + res.key
            if (url) {
              // 将文件上传后的URL地址插入到编辑器文本中
              this.addRange = this.editor.getSelection()
              this.editor.insertEmbed(
                this.addRange ? this.addRange.index : 0,
                'image',
                url,
                Quill.sources.USER
              ) // 调用编辑器的 insertEmbed 方法，插入URL
            } else {
              this.$message.error(this.$tc('homework.insertFailed'))
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.spinning = false
            this.imgUploading = false
          })
      } else {
        this.$message.error(this.$tc('message.invalidFileSize'))
      }
    } else {
      this.$message.error(this.$tc('message.invalidFileFormat'))
    }
  }

  private getDropDownInfo(): void {
    if (this.type === 'courseClass') {
      CourseController.getCoursesOptionsCascade()
        .then(res => {
          this.courses = clearEmptyArray(res.data)
          if (res.data.length) {
            this.InfoForm.setFieldsValue({
              courseId: [
                this.defaultClassId[0] || res.data[0].key,
                this.defaultClassId[1] || res.data[0].subOptions[0].key,
              ],
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      DropDownController.getTeachCcaCourse()
        .then(res => {
          this.courses = res.data
          if (res.data.length) {
            this.InfoForm.setFieldsValue({
              courseId: this.defaultClassId[0],
            })
          }
        })
        .catch(err => console.log(err))
    }
  }

  private changeHandIn(params): void {
    this.handInOnline = params.target.value
    if (!this.handInOnline) {
      this.InfoForm.setFieldsValue({ handInOverDeadline: false })
    }
  }
}
