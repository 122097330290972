














































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { HomeworkController } from '@/services/request.service'
import moment from 'moment'
import FlexTooltip from '@/components/FlexTooltip.vue'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'highlight.js/styles/nord.css'

import hljs from 'highlight.js'
import { quillEditor } from 'vue-quill-editor'
import Quill from 'quill'
import { VNode } from 'vue'
Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    quillEditor,
    FlexTooltip,
  },
})
export default class HomeworkDetail extends Vue {
  private validateStatusScore: Array<any> = []
  private errorMsgScore: Array<any> = []
  private finishMarkHandlerLoading: boolean = false
  private published: boolean = true
  private publishPointsLoading: boolean = false
  private loadingRemindStudents: boolean = false
  private batchFlag: boolean = false
  private days: any = ''
  private homeworkId: any
  private homeworkInfo: any = {}
  private moment = moment
  private readTab = 'complete'
  private homeworkCompletenessInfo: any = {}
  private statusList: any = ['1011', '1012', '1013']
  private scoreGradingList: Array<any> = [
    {
      key: 1,
      value: 'A',
    },
    {
      key: 2,
      value: 'B',
    },
    {
      key: 3,
      value: 'C',
    },
    {
      key: 4,
      value: 'D',
    },
    {
      key: 5,
      value: 'E',
    },
  ]

  private get editorOption(): any {
    return {
      theme: 'snow',
      modules: {
        toolbar: false,
        syntax: {
          highlieght: text => hljs.highlightAuto(text).value,
        },
      },
    }
  }
  private get infoList(): any {
    return [
      this.type === 'courseClass'
        ? {
            label: this.$t('homework.sectionName'),
            value: this.homeworkInfo.sectionName,
          }
        : -1,
      this.type === 'courseClass'
        ? {
            label: this.$t('homework.subjectName'),
            value: this.homeworkInfo.subjectName,
          }
        : -1,
      {
        label: this.$t(`homework.${this.type}`),
        value: this.homeworkInfo.courseName,
      },
      {
        label: this.$t('homework.deadlineTime'),
        value: this.homeworkInfo.endTime
          ? moment(this.homeworkInfo.endTime).format('YYYY-MM-DD')
          : '',
      },
      {
        label: this.$t('homework.gradeType'),
        value: this.homeworkInfo.gradeType
          ? this.$t('homework.gradeTypeList.' + this.homeworkInfo.gradeType)
          : '',
      },
      {
        label: this.$t('homework.handInOnline'),
        value: this.$t(`common.${this.homeworkInfo.handInOnline || false}`),
      },
      {
        label: this.$t('homework.handInAfterDead'),
        value: this.$t(`homework.${this.homeworkInfo.handInOverDeadline ? 'allow' : 'disallow'}`),
      },
    ].filter(item => item !== -1)
  }
  private get columns(): Array<any> {
    let dynamicColumns = [] as any
    let dynamicColumnHandInTime = [] as any
    if (this.homeworkInfo.gradeType !== '1031') {
      dynamicColumns = [
        {
          key: 'score',
          slots: { title: 'scoreTitle' },
          align: 'left',
          scopedSlots: { customRender: 'score' },
          width: 150,
        },
        {
          key: 'comments',
          title: this.$t('homework.comments'),
          align: 'left',
          width: 200,
          scopedSlots: { customRender: 'comments' },
        },
        {
          key: 'scorePublished',
          title: this.$t('homework.scoreStatus'),
          align: 'left',
          scopedSlots: { customRender: 'scorePublished' },
        },
      ]
    }

    if (this.homeworkInfo.handInOnline) {
      dynamicColumnHandInTime = [
        {
          key: 'handInTime',
          title: this.$t('homework.handInTime'),
          align: 'left',
          scopedSlots: { customRender: 'handInTime' },
        },
      ]
    }
    return [
      {
        key: 'name',
        title: this.$t('homework.studentName'),
        align: 'left',
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      {
        key: 'handInStatus',
        title: this.$t('homework.handInStatus'),
        align: 'left',
        scopedSlots: { customRender: 'handInStatus' },
      },
      ...dynamicColumnHandInTime,
      ...dynamicColumns,
      {
        key: 'operation',
        title: this.$t('common.operation'),
        align: 'left',
        scopedSlots: { customRender: 'operation' },
      },
    ]
  }

  private get type(): any {
    return this.$route.params.classType
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (this.batchFlag) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private tabChange(key): void {
    if (this.batchFlag) {
      this.$message.warning(this.$tc('homework.confirmCompleteMarkTip'))
      return
    } else {
      this.readTab = key
    }
  }

  private verifyScore(index): void {
    let score = this.homeworkCompletenessInfo.completeList[index].score
    if (score > 100 || score < 0) {
      this.$set(this.validateStatusScore, index, 'error')
      this.$set(this.errorMsgScore, index, this.$t('homework.unlawScoreTip'))
    } else {
      this.$set(this.validateStatusScore, index, '')
      this.$set(this.errorMsgScore, index, '')
    }
  }

  private get isLegalScore(): boolean {
    let flag = 0
    this.validateStatusScore.forEach(item => {
      if (item === 'error') {
        flag++
      }
    })
    return flag === 0 ? true : false
  }

  @Watch('$route', { immediate: true })
  private onRouteChanged(to: any, from: any) {
    if (to.name === 'homeworkDetail') {
      this.homeworkId = this.$route.params.homeworkId
      this.getData(this.homeworkId)
    }
  }

  private batchMarkHandler(): void {
    if (this.homeworkCompletenessInfo.completeList.length === 0) return
    this.batchFlag = true
  }

  private finishMarkHandler(): void {
    this.finishMarkHandlerLoading = true
    this.homeworkCompletenessInfo.completeList = this.homeworkCompletenessInfo.completeList.map(
      item => {
        return {
          ...item,
          status: item.handInStatus,
        }
      }
    )
    const request = {
      scoreList: this.homeworkCompletenessInfo.completeList,
    }
    console.log('提交数据:', request)
    HomeworkController.setScoreBatch(request)
      .then(res => {
        this.batchFlag = false
        console.log('反馈:', res)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.finishMarkHandlerLoading = false
      })
  }

  private publishPoints(): void {
    if (this.batchFlag) {
      this.$message.warning(this.$tc('homework.confirmCompleteMarkTip'))
      return
    }
    this.publishPointsLoading = true
    HomeworkController.publishScore(this.homeworkId)
      .then(res => {
        console.log('反馈数据:', res)
        this.getData(this.homeworkId)
        this.published = true
        this.$message.success(this.$tc('homework.publishScoreSuccess'))
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.publishPointsLoading = false
      })
  }

  private remindStudents(): void {
    this.loadingRemindStudents = true
    HomeworkController.sendMessage(this.homeworkId)
      .then(res => {
        this.$message.success(this.$tc('homework.remindSuccess'))
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loadingRemindStudents = false
      })
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'homeworkDetail'
  }

  private viewDetail(record): void {
    const homeworkStudentId = record.homeworkStudentId.toString()
    const scorePublished = record.scorePublished.toString()
    const canSetScore = this.homeworkCompletenessInfo.canSetScore.toString()
    this.$router.push({
      name: 'studentHomeworkDetail',
      query: { homeworkStudentId, scorePublished, canSetScore },
    })
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private getData(homeworkId): void {
    HomeworkController.detail(homeworkId)
      .then(res => {
        console.log('scoreGradingList:', this.scoreGradingList)
        console.log('作业详情', res.data)
        this.homeworkInfo = res.data
        this.days = moment(this.homeworkInfo.endTime).diff(moment(), 'days') + 1
      })
      .catch(err => {
        console.error(err)
      })
    HomeworkController.getPerformance(homeworkId).then(res => {
      this.homeworkCompletenessInfo = res.data
      this.homeworkCompletenessInfo.completeList = this.homeworkCompletenessInfo.completeList.map(
        item => {
          return {
            ...item,
            status: item.handInStatus,
          }
        }
      )
      console.log('完成情况:', this.homeworkCompletenessInfo)
      // this.published = this.homeworkCompletenessInfo.completeList.some( item => item.scorePublished === false)
      for (let i = 0; i < this.homeworkCompletenessInfo.completeList.length; i++) {
        if (this.homeworkCompletenessInfo.completeList[i].scorePublished === false) {
          this.published = false
          break
        }
      }
      console.log('this.published2:', this.published)
    })
  }
}
