































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { HomeworkController } from '@/services/request.service'
import moment from 'moment'

Component.registerHooks(['beforeRouteLeave'])

@Component({})
export default class StudentHomeworkDetail extends Vue {
  private canSetScore: boolean = false
  private editFlag: boolean = false
  private scorePublished: boolean = false
  private saveMarkLoading: boolean = false
  private MarkInfoForm: any
  private homeworkStudentId: any
  private homeworkInfo: any = {}
  private moment = moment
  private formLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }
  private get infoList(): any {
    return [
      {
        label: this.$t('homework.name'),
        value: this.homeworkInfo.name,
      },
      {
        label: this.$t('homework.handInOnline'),
        value: this.homeworkInfo.handInOnline ? this.$t('common.true') : this.$t('common.false'),
      },
      this.homeworkInfo.handInOnline && this.type === 'courseClass'
        ? {
            label: this.$t(`homework.handInTime`),
            value: this.moment(this.homeworkInfo.endTime).format('YYYY-MM-DD HH:mm'),
          }
        : -1,
      this.type === 'courseClass'
        ? {
            label: this.$t('homework.sectionName'),
            value: this.homeworkInfo.sectionName,
          }
        : -1,
      this.type === 'courseClass'
        ? {
            label: this.$t('homework.subjectName'),
            value: this.homeworkInfo.subjectName,
          }
        : -1,
      {
        label: this.$t('homework.courseName'),
        value: this.homeworkInfo.courseName,
      },
    ].filter(item => item !== -1)
  }
  private get type(): any {
    return this.$route.params.classType
  }
  private statusList: any = ['1011', '1012', '1013']
  private scoreGradingList: Array<any> = [
    {
      key: 1,
      value: 'A',
    },
    {
      key: 2,
      value: 'B',
    },
    {
      key: 3,
      value: 'C',
    },
    {
      key: 4,
      value: 'D',
    },
    {
      key: 5,
      value: 'E',
    },
  ]
  private options = {
    whiteList: {
      br: [],
    },
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (
        !this.editFlag &&
        !this.scorePublished &&
        this.homeworkInfo.gradeType !== '1031' &&
        this.canSetScore
      ) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private beforeCreate(): void {
    this.MarkInfoForm = this.$form.createForm(this)
  }

  private created(): void {
    this.$nextTick(() => {
      this.homeworkStudentId = Number(this.$route.query.homeworkStudentId)
      this.scorePublished = this.$route.query.scorePublished === 'true' ? true : false
      this.canSetScore = this.$route.query.canSetScore === 'true' ? true : false
      this.getData(this.homeworkStudentId)
    })
  }

  private validateScoreRange(rule, value, callback): void {
    const form = this.MarkInfoForm
    const score = form.getFieldValue('score')
    if (Number(score) < 0 || Number(score) > 100) {
      callback(this.$t('homework.unlawScoreTip'))
    } else {
      callback()
    }
  }

  private edit(): void {
    this.editFlag = false
    this.scorePublished = false
  }

  private saveMark(): void {
    this.saveMarkLoading = true
    const form = this.MarkInfoForm
    form.validateFields((err: any, values: any) => {
      if (err) {
        this.saveMarkLoading = false
        return
      } else {
        const request = {
          homeworkStudentId: this.homeworkInfo.homeworkStudentId,
          ...values,
        }
        console.log('提交数据:', request)
        HomeworkController.setScore(request)
          .then(res => {
            console.log('反馈', res.data)
            this.$message.success(this.$tc('common.saveSuccess'))
            this.editFlag = true
            this.$router.go(-1)
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.saveMarkLoading = false
          })
      }
    })
  }

  private cancel(): void {
    this.$router.back()
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private getData(homeworkStudentId): void {
    HomeworkController.getStudentHomeworkDetail(homeworkStudentId)
      .then(res => {
        console.log('学生作业详情', res.data)
        this.homeworkInfo = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }
}
